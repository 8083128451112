'use client'; // 错误组件必须是客户端组件

import { useEffect, ErrorInfo } from 'react';

interface ErrorProps {
    error: Error | ErrorInfo;
    reset: () => void;
}

export default function Error({ error, reset }: ErrorProps) {
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <div>
            <h2>Error!</h2>
            <button
                onClick={
                    // 尝试恢复
                    () => reset()
                }
            >
                Try again
            </button>
        </div>
    );
}
